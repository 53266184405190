import { GetStaticPropsResult } from 'next'
import dynamic from 'next/dynamic'

import { homePageModuleQuery } from '@data/sanity/queries/page'
import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { SanityHomePage } from '@data/sanity/queries/types/page'
import { getShopifyDomain } from '@lib/shopify/client'
import { getPageSanityClient } from '@lib/sanity/client'
import { defaultModuleQueryParameters, getHomePage } from '@lib/sanity/page'
import { Locale } from '@lib/language'
import { getReviews, Reviews } from '@lib/review'
import { CustomStaticPropsContext } from '@lib/routes'

import Layout from '@components/layout'
import Modules from '@modules/modules'
import PreviewModules from '@modules/preview-modules'

const PreviewProvider = dynamic(() => import('@lib/sanity/preview'))

interface HomePageProps {
  draftMode: boolean
  draftToken?: string
  locale: Locale
  page: SanityHomePage
  site: SanitySiteFragment
  reviews?: Reviews
}

const HomePage = ({
  draftMode,
  draftToken,
  locale,
  page,
  site,
  reviews,
}: HomePageProps) => {
  if (draftMode && draftToken) {
    return (
      <Layout page={page} site={site} draftMode>
        <PreviewProvider draftToken={draftToken}>
          <PreviewModules
            modules={page.modules}
            moduleQuery={homePageModuleQuery}
            moduleQueryParams={{
              ...defaultModuleQueryParameters,
              locale,
            }}
          />
        </PreviewProvider>
      </Layout>
    )
  }

  return (
    <Layout page={page} site={site}>
      <Modules modules={page.modules} reviews={reviews} />
    </Layout>
  )
}

export async function getStaticProps(
  context: CustomStaticPropsContext
): Promise<GetStaticPropsResult<HomePageProps>> {
  const [sanityClient, draftMode, draftToken] = getPageSanityClient(
    context.draftMode
  )
  const homePage = await getHomePage(sanityClient, context.locale)

  if (!homePage.page) {
    return { notFound: true }
  }

  // Get Stamped.io reviews
  const shopifyDomain = getShopifyDomain(context.locale)
  const reviews = await getReviews(
    homePage.page.modules ?? [],
    shopifyDomain,
    homePage.site.settings.stampedApiKey
  )

  return {
    props: {
      draftMode,
      draftToken,
      locale: context.locale,
      page: homePage.page,
      site: homePage.site,
      reviews,
    },
    revalidate: 60,
  }
}

export default HomePage
